<template>
  <presentation-message :list="list" @done="$emit('done')"></presentation-message>
</template>

<script>
  import PresentationMessage from "../../component/PresentationMessage";
  export default {
    name: "SuperBuilderStep5",
    components: {PresentationMessage},
    data() {
      return {
        list: [
          '서비스 구성이 완료되었습니다.',
          '이제 화면 설정이 남았어요!',
          '세부적인 디자인 설정 전',
          '주요 페이지부터 먼저 골라볼게요 :)'
        ]
      }
    }
  }
</script>